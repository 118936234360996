@use '@angular/material' as mat;
@use '@sama/ui-library/theme' as *;

//$primary color
$primary: mat.m2-get-color-from-palette($mat-principal, 500);

//$accent color
$accent: mat.m2-get-color-from-palette($mat-accent, 500);

//$warn color
$warn: mat.m2-get-color-from-palette($mat-warn, 500);

//$variables
$border-divider: mat.m2-get-color-from-palette($mat-accent, 50);
$sub-title: mat.m2-get-color-from-palette($mat-accent, 300);
$sticky-menu-background-color: mat.m2-get-color-from-palette($mat-base, 500);
$checkbox-checked-check-color: mat.m2-get-color-from-palette($mat-base, 500);
$benefit-icon-color: #20b55a;
