@use './variables/index' as main;

.svg-asset {
  .fill-primary {
    fill: main.$color-primary;
  }
  .stroke-primary {
    stroke: main.$color-primary;
  }
  .fill-accent {
    fill: main.$color-accent;
  }
  .stroke-accent {
    stroke: main.$color-accent;
  }
  .fill-warn {
    fill: main.$color-warn;
  }
  .stroke-warn {
    stroke: main.$color-warn;
  }
}
