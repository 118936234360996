@use '@sama/design-tokens/styles/variables/colors' as *;
@use './app/styles/variables/index' as main;
@import './app/styles/svg.scss';

/* You can add global styles to this file, and  also import other style files */

html,
body {
  height: 100%;
  /* border-box box model allows us to add padding and border to our elements without increasing their size */
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: main.$font-family-FONT_FAMILIES_MAISON_NEUE;
}

.mat-drawer-backdrop {
  background-color: $BACKDROP_OVERLAY !important;
}

.mat-drawer-container {
  background-color: #fff !important;
}

sama-forbidden-error {
  margin-top: 0 !important;
}

sama-not-found-error {
  margin-top: 0 !important;
}
