@font-face {
  font-family: "Maison Neue";
  src: url("./MaisonNeue/woff2/MaisonNeueWEB-Book.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Maison Neue";
  src: url("./MaisonNeue/woff2/MaisonNeueWEB-BookItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Maison Neue";
  src: url("./MaisonNeue/woff2/MaisonNeueWEB-Demi.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Maison Neue";
  src: url("./MaisonNeue/woff2/MaisonNeueWEB-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}